.App {
  /* text-align: center; */
  background-color: #282c34;
min-height: 100vh;
background-size: auto;

  /* background-image: url(../src/images/bg-img.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-backgrond-size: cover;
  -o-background-size: cover;
  background-size: cover; */
} 



.main-font {
  font-family: "Bebas Neue"
}

.secondary-font {
  font-family: "Roboto";
  font-weight: 300;
}

.secondary-font-bold {
  font-family: "Roboto";
  font-weight: 700;
}

.zoom {
  transition: transform .5s;
}
.zoom:hover {
  /* -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5); */
  transform: skewX(1deg)
}

.footer {
  width: 100%;
  position: absolute;
  /* bottom: 0; */
}

#bg {
  position: fixed;
  top: 0px;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.formParent {
  position: relative
}

.form {
  position: absolute;
  /* top: 70%; */
  height: 100px;
 
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* All my added stuff */

body {
  font-family: "Roboto", sans-serif;
}

.sidebar {
  height: 100%;
  width: 85px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  transition: 0.5s;
  overflow-x: hidden;
  padding-top: 60px;
  white-space: nowrap;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidebar a:hover {
  color: #f1f1f1;
}

/* main .sidebar {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
} */

.material-icons,
.icon-text {
  vertical-align: middle;
}

.material-icons {
  padding-bottom: 3px;
  margin-right: 30px;
}

.margin-size {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

@media only screen and (max-width: 450px) {
  .margin-size {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

